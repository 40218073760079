<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout wrap align-center justify-center fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title :title="'Producto nuevo'" :subtitle="'---'"></app-title>
        </v-flex>
        <v-flex xs12 lg8 xl8>
          <v-card>
            <app-title-card
              :title="'Rellene el formulario'"
              :subtitle="'Todos los campos son obligatorios'"
            ></app-title-card>
            <v-divider></v-divider>
            <v-card-text>
              <v-layout wrap align-center justify-center fill-height>
                <v-flex xs12 sm4>
                  <v-text-field v-model="product.name" label="Nombre" placeholder="Francisca"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4>
                  <v-text-field v-model="product.url" label="Url" placeholder="caja-con-12-rosas"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4>
                  <v-text-field v-model="product.price" label="Precio" placeholder="10000" type="number"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <QuillEditorFormat
                    v-model="product.description"
                    :reference="'descriptionOrder'"
                    placeholder="Descripcion del producto"
                  >
                  </QuillEditorFormat>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :loading="loading" :disabled="loading" color="accent" flat @click="save"> Listo </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import qs from 'qs'
import { GET_API, ADD_PRODUCT_NEW } from '../../config'
import appTitle from '../useful/title.vue'
import appTitleCard from '../useful/titleCard.vue'
import QuillEditorFormat from '../useful/quillEditorFormat.vue'

export default {
  name: 'ProductAdd',
  components: { appTitle, appTitleCard, QuillEditorFormat },
  data: () => ({
    product: {
      name: null,
      url: null,
      description: null,
      price: null
    },
    loading: false
  }),
  watch: {
    'product.name': function productName() {
      this.product.url = this.toSeoUrl(this.product.name)
    }
  },
  methods: {
    async save() {
      this.loading = true
      try {
        const res = await this.$http({
          method: 'post',
          url: ADD_PRODUCT_NEW,
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify({
            p: 'addProductStore',
            product: this.product
          })
        })
        this.$store.dispatch('changeSnack', { active: true, text: res.data.mensaje })
        if (!res.data.error) {
          this.$router.push({ name: 'productPrice', params: { id: res.data.idproducto } })
        }
        setTimeout(() => {
          this.loading = false
        }, 500)
      } catch (e) {
        console.log('ERROR=>', e)
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    toSeoUrl(url) {
      return url
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, '-')
        .toLowerCase()
        .replace(/&/g, '-and-')
        .replace(/[^a-z0-9\-]/g, '')
        .replace(/-+/g, '-')
        .replace(/^-*/, '')
        .replace(/-*$/, '')
    }
  }
}
</script>
